@use './styles';

$padding: styles.$padding;

.modal-header {
  padding: 20px $padding*2 15px;
  border-radius: 3px 3px 0 0;
  background: styles.$Dahlia;
}

.modal-body {
  padding: $padding $padding*2 $padding;
}

.modal-content {
  z-index: 1000;
  background-color: white !important;
  border-width: 0;
  border-radius: 3px;
}

.modal-close {
  top: 12px !important;
  font-size: 1.4em !important;
  right: 22px !important;
  position: absolute;
  color: styles.$Lily;
  cursor: pointer;

  &:hover {
    color: white;
  }
}

.modal-dialog {
  max-width: 800px;
  padding-top: 100px;
}

#recommend-modal .modal-dialog {
  max-width: 600px;
}

.customize-grid {
  display: -ms-grid;
  display: grid;
  margin-bottom: 8px;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  @media #{styles.$max_media_size_mobile} {
    display: block;
  }

  ul {
    padding: 0 !important;
    font-size: 0.9em;

    hr {
      border-color: styles.$Dahlia;
    }

    li {
      list-style: none !important;
      padding: 4px 20px 4px 12px;
      cursor: pointer;
      font-size: 0.95em;
      position: relative;
      border: 1px solid #dedede;
      border-radius: 4px;
      margin-bottom: 6px;

      &:hover {
        color: styles.$Bluebell;
        border-color: styles.$Bluebell;
      }
    }
  }

  & .customize-column:first-of-type {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }

  & .customize-column:nth-of-type(2) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }

  & .customize-column:nth-of-type(3) {
    -ms-grid-column: 3;
    -ms-grid-column-span: 1;
  }

  & .customize-column:nth-of-type(4) {
    -ms-grid-column: 4;
    -ms-grid-column-span: 1;
  }
}

.recommend-modal-col.customize-grid {
  grid-template-columns: 1fr;
}

.customize-grid-options {
  display: grid;
  grid-template-columns: 1fr 1fr;

  li:first-of-type {
    margin-right: 14px;
  }
}

.customize-column {
  padding: 4px 0 4px 16px;
  //border-right: 1px solid #ddd;
  &:last-of-type {
    border: none !important;
  }

  @media #{styles.$max_media_size_mobile} {
    border: none;
  }
}
