@use './animations';
@forward './animations';
@import 'ngx-toastr/toastr';
//@import 'bootstrap/scss/bootstrap';

/* ReynoldsWorld brand colors */
$Columbine: #004987;
$Dark_Dahlia: #262D3A;
$Dahlia: #384053;
$Light_Dahlia: #697486;
$Bluebell: #1E88E5;
$Marigold: #F9A20A;
$Succulent: #A7BB39;
$Poppy: #FF3851;
$Orchid: #A54BE5;
$Black: #333;
$Light_Lily: #F6F6F6;
$Lily: #DDDDDD;
$Dark_Lily: #CFD1D6;

$Success: #78b238;

$min_media_size_large: "(min-width: 1581px)";
$media_size_medium:"(min-width: 1302px) and (max-width: 1580px)";
$max_media_size_medium: "(max-width: 1580px)";
$media_size_small: "(min-width: 1001px) and (max-width: 1301px)";
$max_media_size_small: "(max-width: 1301px)";
$media_size_tiny: "(min-width: 731px) and (max-width: 1000px)";
$max_media_size_tiny: "(max-width: 1000px)";
$max_media_size_mobile: "(max-width: 730px)";

$page_padding: 220px;
$padding: 12px;

$svg_url: '../assets/';

@media #{$min_media_size_large}{
  .small{
    display: none !important;
  }
}
@media #{$max_media_size_medium}{
  .small{
    display: block !important;
  }
  .big{
    display: none !important;
  }
}

// Typography
.source-sans{
  font-family: 'Source Sans Pro', sans-serif !important;
}
.frank-ruhl{
  font-family: 'Frank Ruhl Libre', serif;
}

label{
  margin-bottom: 0;
}
body{
  background-color: $Light_Lily !important;
  font-size: 15px;
  @media print{
    background-color: white !important;
    width: 100% !important;
    padding: 0.25in !important;
    font-size: 18px !important;
    color: black !important;
    *{
      padding: 0;
    }
    &:before{
      position: absolute;
      top: 0;
      left: 0.25in;
      content: "Accessed from home.reyrey.com on " var(--current-date-full) ". © Copyright " var(--current-year) " Reynolds and Reynolds. All rights Reserved.";
      font-size: 10px;
    }
  }
}
hr{
  margin-top: 0.6rem !important;
  margin-bottom: 0.6rem !important;
}

/* Decorators */
.small-menu-shadow{
  box-shadow: 1px 1px 3px 0px rgba(0,0,0,0.3);
}
.shadowed{
  -webkit-box-shadow: 0 3px 5px 0 rgba(0,0,0,0.3), 0 2px 10px 0 rgba(0,0,0,0.12);
  box-shadow: 0 3px 3px 0 rgba(0,0,0,0.3), 0 2px 5px 0 rgba(0,0,0,0.12);
}
.hidden{
  display: none !important;
}

/* Main page styling */
.main{
  padding: 0 $page_padding - 8px;
  min-height: 100vh;
  @media #{$media_size_medium}{
    padding: 0 125px - 8px;
  }
  @media #{$media_size_small}{
    padding: 0 105px - 8px;
  }
  @media #{$media_size_tiny}{
    padding: 0 30px - 8px;
  }
  @media #{$max_media_size_mobile}{
    padding: 0;
  }
}
.main-with-sidebar{
  padding: 0 (210px - 8px) 0 (155px - 8px);
  min-height: 100vh;
  @media #{$media_size_medium}{
    padding: 0 100px - 8px;
  }
  @media #{$media_size_small}{
    padding: 0 90px - 8px;
  }
  @media #{$media_size_tiny}{
    padding: 0 25px - 8px;
  }
  @media #{$max_media_size_mobile}{
    padding: 0;
  }
  @media print{
    padding: 0 !important;
    min-height: 10vh !important;
  }
}

/* Typography Styles */
a{
  @extend .inline-link;
}
h1{
  line-height: 1.42857143;
  margin: 0;
  @extend .header-1;
}
h2{
  line-height: 1.42857143;
  margin: 0;
  @extend .header-2;
}
h3{
  line-height: 1.42857143;
  margin: 0;
  @extend .header-3;
}
h4{
  line-height: 1.42857143;
  margin: 0;
  @extend .header-4;
}
h5{
  line-height: 1.42857143;
  margin: 0;
  @extend .header-5;
}
h6{
  line-height: 1.42857143;
  margin: 0;
  @extend .header-6;
}
.header-1{
  @extend .frank-ruhl;
  font-size: 2.353em;
  color: $Dahlia;
  font-weight: 600;
  margin-bottom: calc(#{$padding}/2);
}
.header-2{
  @extend .source-sans;
  font-size: 1.705em;
  color: $Dahlia;
  font-weight: 600;
  margin-bottom: calc(#{$padding}/2);
}
.header-3{
  @extend .source-sans;
  font-size: 1.294em;
  background-color: $Dahlia;
  color: white;
  font-weight: 600;
  padding: 12px;
  border-radius: .25rem .25rem 0 0;
  &.special{
    background-color: $Bluebell;
  }
}
.header-4{
  @extend .frank-ruhl;
  font-size: 1.118em;
  color: $Dahlia;
  margin: 5px 0;
  font-weight: 600;
}
.header-5{
  @extend .frank-ruhl;
  font-size: 1.118em;
  color: $Dahlia;
  font-weight: 600;
}
.header-6{
  @extend .source-sans;
  font-size: 1em;
  margin-bottom: 4px;
  color: $Dahlia;
  font-weight: 600;
}
.inline-link{
  color: $Columbine !important;
  cursor: pointer;
  text-decoration: underline;
  @extend .source-sans;
  &:hover{
    color: $Bluebell !important;
  }
}
.body-copy{
  @extend .source-sans;
  margin: 4px 0;
  color: #333;
  font-size: 1em;
}
.list-copy{
  @extend .source-sans;
  margin: 4px 0 0;
  color: $Light_Dahlia;
  font-size: 0.9em;
}

// Loading
.loader-container{
  height: 50px;
  display: flex;
  text-align: left;
  float: left;
}
.loader-fullpage-container{
  width: 100%;
  text-align: center;
  padding: 16px 0;
}
.loader-lite{
  border: 2px solid $Light_Lily;
  border-radius: 50%;
  border-top: 2px solid $Light_Dahlia;
  width: 16px;
  display: inline-block;
  height: 16px;
  margin-left: 8px;
  -webkit-animation: spin 1.2s ease-in-out infinite; /* Safari */
  animation: spin 1.2s ease-in-out infinite;
}
.loader {
  border: 6px solid $Dark_Lily;
  border-radius: 50%;
  border-top: 6px solid $Dahlia;
  width: 40px;
  display: inline-block;
  height: 40px;
  margin-top: auto;
  margin-bottom: auto;
  -webkit-animation: spin 1.2s ease-in-out infinite; /* Safari */
  animation: spin 1.2s ease-in-out infinite;
}
.loader-large{
  @extend .loader;
  width: 60px;
  height: 60px;
  border-width: 8px;
  -webkit-animation: spin 2s ease-in-out infinite; /* Safari */
  animation: spin 2s ease-in-out infinite;
}
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.no-results{
  @extend .source-sans;
  font-size: 18px;
  color: $Light_Dahlia;
  font-weight: 400;
  font-style: italic;
  padding: $padding*2;
}
.load-more{
  @extend .source-sans;
  font-size: 14px;
  color: $Dahlia;
  font-weight: 400;
  cursor: pointer;
  padding: $padding*2;
  text-decoration: underline;
}

/* Status colors */
.status-text {
  font-size: 0.9em;
  letter-spacing: -0.3px;
  font-weight: 600;
  font-style: italic;
}
.success {
  @extend .status-text;
  color: $Success;
}
.warning {
  @extend .status-text;
  color: $Marigold;
}
.error {
  @extend .status-text;
  color: $Poppy;
}

/* Stand-in styles */
.standin-header{
  background-color: fade-out($Dahlia, 0.85);
  height: 25px;
  margin: 10px 0;
  border-radius: 2px;
  width: 75%;
}
.standin-page-header{
  background-color: fade-out($Dahlia, 0.65);
  height: 36px;
  margin: 12px 0;
  border-radius: 2px;
  width: 55%;
}
.standin-page-body{
  background-color: fade-out($Dahlia, 0.75);
  height: 190px;
  width: 80%;
  margin: 10px 0;
  border-radius: 2px;
}
.standin-date{
  background-color: fade-out($Dahlia, 0.92);
  height: 15px;
  margin: 10px 0;
  border-radius: 2px;
  width: 15%;
}
.standin-copy{
  background-color: fade-out($Dahlia, 0.85);
  height: 50px;
  width: 100%;
  margin: 10px 0;
  border-radius: 2px;
}
.standin-body-copy{
  background-color: fade-out($Dahlia, 0.85);
  height: 50px;
  width: 80%;
  margin: 10px 0;
  border-radius: 2px;
}
.standin-news-footer{
  width: 100%;
  height: 30px;
  border-radius: 2px;
  margin: 10px 0;
}
.standin-link{
  background-color: fade-out($Columbine, 0.92);
  height: 20px;
  margin: 10px 0;
  border-radius: 2px;
  width: 75%;
}
.quick-link-standin{
  height: 24px;
  width: 92%;
  margin-bottom: 20px;
  background-color: fade-out($Dahlia, 0.92);
}

// Typeahead
.twitter-typeahead{
  display: block !important;
  width: 100% !important;
  width: stretch !important;
}
.tt-menu{
  width: 100% !important;
  width: stretch !important;
  background-color: white;
  display: none !important;
}
.tt-suggestion{
  padding: 5px 15px;
  font-size: 1.05em;
  cursor: pointer;
}
.tt-suggestion:hover{
  background-color: $Columbine;
  color: white;
}

// Browser check warning
.browser-warning{
  @extend .source-sans;
  position: fixed;
  z-index: 50000;
  right: 40px;
  display: none;
  bottom: 40px;
  padding: 20px 58px 20px 30px;
  background: $Dahlia;
  color: white;
  .browser-warning-main{
    font-size: 1.1em;
  }
  .browser-warning-sub{
    font-size: 0.85em;
    color: $Lily;
  }
  span{
    font-size: 1.4em;
    right: 20px;
    top: 12px;
    position: absolute;
    cursor: pointer;
    &:hover{
      color: white;
    }
  }
}

.pinned-icon {
  margin-right: 5px;
}

