@keyframes pulse {
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
.throb{
  animation: throb 200ms ease-in-out;
}
@keyframes throb{
  0% {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(0.75, 0.75, 0.75);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}
@keyframes spin-out {
  0%{
    opacity: 1;
    transform: rotate(0deg);
  }
  100%{
    opacity: 0;
    transform: rotate(270deg);
  }
}
@keyframes spin-in{
  0%{
    opacity: 0;
    transform: rotate(-90deg);
  }
  100%{
    opacity: 1;
    transform: rotate(0deg);
  }
}
