@use './styles';

.file {
  padding: 16px 12px;
  background: #eee;
  border-left: 3px solid styles.$Dahlia;
  margin: 10px 0;
}
.video{
  padding-bottom: 10px;
}
.video-holder{
  width: 100%;
  display: flex;
  background: styles.$Dark_Dahlia;
  height: 400px;
  cursor: pointer;
  margin-bottom: 20px;
  iframe{
    margin: 0 auto;
    width: 100%;
    height: 100%;
    border: none;
  }
}
.audio{
  width: 100%;
  padding: 30px 5px;
  margin: 30px 0;
  border-top: 1px solid #bbb;
  audio{
    width: 100%;
    margin: 5px 0 15px;
  }
}
.search-show, .favorites-filter{
  position: relative;
  margin-bottom: 12px;
  @extend .source-sans;
  .search-filter{
    display: inline-block;
    font-size: 0.9em;
    top: 6px;
    margin-right: 20px;
  }
  &.favorites-filter {
    margin: 12px 12px 0;
  }
}
.pdf embed, #fullpage-viewer embed{
  width: 100%;
  height: 90vh;
}
