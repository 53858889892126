@use './styles';

/**
  Styles associated with the filter-dropdown and filter-toggle components. These components serve to replace
  multiselect and checkmark inputs respectively, by providing all the of the desired visual behavior of each without
  using javascript to achieve them. Additionally they can be fully styled and customized for any application.
 */

// Background colors
$filter-background: transparent;              // The default state background
$filter-background-active: #f2f2f2;           // The active state background for the filter element

// Text colors
$filter-text-color: styles.$Lily;             // The default state text color
$filter-detail-color: styles.$Dahlia;      // The text color for the text indicating how many items are selected
$multiselect-text-color: styles.$Dark_Dahlia;

$checkmark-color: styles.$Dahlia;
$checkmark-color-active: styles.$Bluebell;

// Other
$border-radius: 0px;
$list-item-expand-height: 30px;               // The height each item in the dropdown list should be after the menu is expanded
$border-style: 1px solid #bbb;                // The separation border
$transition-style: all 0.2s ease-in-out, background 0.05s ease, color 0.05s ease;

.expand-toggle-container{
  position: relative;
  padding: 0;
}

label.expand-toggle, label.multiselect-toggle{
  position: relative;
  text-transform: capitalize;
  border-radius: $border-radius;
  background: $filter-background;
  color: $filter-text-color;
  cursor: pointer;
  margin: 0 0 0 16px;
  display: inline-block;
  font-size: 0.85em;
  border-bottom: 1px solid #bbb;
  font-weight: 600;
  letter-spacing: 0.4px;
  z-index: 200;
  line-height: 2em;
  padding: 0;
  transition: border-radius 0.15s ease;
  text-align: left;
  height: auto;
  width: auto;

  &.multiselect-toggle {
    color: $multiselect-text-color;
    border-color: $multiselect-text-color;
    z-index: 80;
  }

  .expand-toggle-text{
    padding: 0 50px 0 34px;
  }

  &.multiselect-toggle .expand-toggle-text {
    padding: 0 12px 0 8px;
  }

  .expand-toggle-choices{
    margin: 0;
    text-transform: none;
    padding: 0;
    max-height: 250px;
    position: relative;
    overflow-y: auto;
    list-style: none;
    li, label{
      height: 0;
      display: block;
      cursor: pointer;
      overflow: hidden;
      min-width: 100px;
      line-height: $list-item-expand-height;
      transition: $transition-style;
      color: styles.$Dark_Dahlia;
      padding: 0 10px;
      text-align: left;
      position: relative;
      font-weight: 400;
      .fas {
        margin-right: 10px;
        font-size: 0.9em;
      }
      &:hover{
        color: $filter-text-color;
        background: styles.$Dark_Dahlia;
        .check {
          border-color: #bbb !important;
        }
      }
    }
    label {
      padding: 0 10px 0 28px;
    }
  }

  &:before{
    content: "";
    display: none;
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  @media #{styles.$max_media_size_small} {
    max-width: 200px;
    font-size: 0.75em;
  }
}

.checkmark-toggle.no-pad {
  margin: 0 !important;
  border: none;
}

.expand-toggle-caret{
  position: absolute;
  left: 14px;
  top: 1.2em;
  transition: $transition-style;

  &:before, &:after{
    content: "";
    background: $filter-text-color;
    height: 2px;
    width: 6px;
    transition: $transition-style;
    position: absolute;
  }
  &:before{
    transform: translateX(0%) rotate(-45deg)
  }
  &:after{
    transform: translateY(-150%) translateX(0%) rotate(45deg);
  }
}

input:checked + .expand-toggle{
  background: $filter-background-active;
  border-radius: calc(#{$border-radius} / 6);
  color: styles.$Dark_Dahlia;
  border-color: styles.$Dark_Dahlia;
  li, label{
    height: $list-item-expand-height;
  }
  &:before{
    display: block;
  }
  .expand-toggle-caret{
    transform: rotate(90deg);
  }
  .expand-toggle-buttons{
    height: $list-item-expand-height;
    border-top: 1px solid #ddd;
  }
}

input:checked + .multiselect-toggle {
  background: white;
  border: 1px solid $multiselect-text-color;
}

.checkmark-toggle{
  .checkmark-toggle-text{
    padding: 0 24px 0 34px;
  }
  .checkmark-icon{
    position: absolute;
    top: 12px;
    left: 10px;
  }
  .reysat-checkmark{
    left: 9px;
  }
}

.expand-toggle-selected{
  color: $filter-detail-color;
  border-left: $border-style;
  font-weight: 400;
  margin: 0 20px 0 8px;
  padding-left: 8px;
  font-size: 0.85em;
}

.expand-toggle-icon {
  position: absolute;
  left: 10px;
  top: 8px;
}

input:checked + .expand-toggle .expand-toggle-search {
  height: $list-item-expand-height;
  border-bottom: $border-style;
}

.expand-toggle-buttons {
  height: 0;
  overflow: hidden;
  transition: height 0.2s ease 0.1s;
  display: grid;
  grid-template-columns: 1fr 1fr;
  button {
    height: 100%;
    text-align: center;
    line-height: $list-item-expand-height;
    color: styles.$Dahlia;
    letter-spacing: 0.5px;
    padding: 0;
    border: none;
    font-size: 0.9em;
    background: transparent;
    &:hover {
      background: styles.$Dahlia;
      color: white;
    }
    &:first-of-type {
      border-right: 1px solid #ddd;
    }
  }
}
.reysat-checkmark{
  width: 18px;
  height: 18px;
  position: absolute;
  left: 4px;
  border-radius: 2px;
  top: 50%;
  transform: translateY(-50%);
  .check{
    width: 13px;
    height: 13px;
    border: 1px solid $checkmark-color;
    position: absolute;
    transition: all 0.3s;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 50;
    background: transparent;
    opacity: 0.3;
  }
}
.active .reysat-checkmark .check{
  transform: translate(-50%, -50%) rotate(45deg);
  position: absolute;
  width: 6px;
  opacity: 1;
  height: 12px;
  border: solid $checkmark-color-active;
  border-width: 0 3px 3px 0;
  transition: all 0.3s;
  z-index: 50;
}
