@use './styles';

// Inputs
.filter-input{
  border: 1px solid styles.$Lily;
  color: styles.$Dahlia;
  width: 100%;
  border-radius: 4px;
  padding: 4px 12px;
  height: 40px;
  margin: 5px 0 10px;
  background: white;
  font-size: 0.9em;
  & + .wws-search-btn{
    top: 18px !important;
  }
}
.exposure-filter{
  @extend .source-sans;
  width: 100%;
  padding: 0;
  z-index: 5;
  border-bottom: 1px solid styles.$Bluebell;
  font-size: 0.6vw;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  &::before{
    content: '';
    position: absolute;
    top: 0;
    width: 33.33333%;
    bottom: 0;
    z-index: -1;
    background: styles.$Bluebell !important;
    transition: all 0.2s ease;
  }
  & div{
    -ms-grid-column-span: 1;
  }
  & div:first-of-type{
    -ms-grid-column: 1;
  }
  & div:nth-of-type(2){
    -ms-grid-column: 2;
  }
  & div:nth-of-type(3){
    -ms-grid-column: 3;
  }
  &.first div:first-of-type, &.second div:nth-of-type(2), &.third div:nth-of-type(3){
    color: white;
    font-weight: 600;
  }
  &.first::before{ left: 0; }
  &.second::before{ left: 33.33333%; }
  &.third::before{ left: 66.66666%; }
  *{
    text-align: center;
    padding: 4px 0;
    cursor: pointer;
    height: 100%;
    border-right: 1px solid styles.$Bluebell;
    color: styles.$Bluebell;
    &:hover{
      background: fade(styles.$Bluebell, 10%);
    }
  }
  *:last-of-type{
    border: none !important;
  }
  &.two-filter {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    &:before {
      width: 50% !important;
    }
    &.second::before{ left: 50% !important; }
  }
}
.small-filter.exposure-filter{
  border: 1px solid styles.$Bluebell !important;
  font-size: 0.8em !important;
  width: 250px !important;
  border-radius: 3px;
  margin-bottom: 10px;
  *{
    padding: 2px 0;
  }
}
.large-filter.exposure-filter{
  border: 1px solid styles.$Bluebell !important;
  font-size: 0.85em !important;
  width: 350px !important;
  display: -ms-inline-grid !important;
  display: inline-grid !important;
  border-radius: 3px;
  *{
    padding: 2px 0;
  }
  &.two-filter {
    width: 230px !important;
  }
}
#filter-open{
  display: none;
}
input:checked ~ .filters-holder{
  height: auto !important;
  padding: 8px 0px !important;
}
.filters-holder{
  height: 0;
  padding: 0;
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 1fr 1fr 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: start;
  overflow-y: hidden;
  @media #{styles.$max_media_size_tiny} {
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
  }
}
.filter-category{
  -ms-grid-column-span: 1;
  display: inline-block;
  display: inline-grid;
  margin-right: 32px;
  margin-top: 15px;
  ul{
    padding: 0;
    margin: 0;
  }
  li{
    list-style: none;
    cursor: pointer;
    position: relative;
    font-size: 0.85em;
    border-bottom: 1px solid #ddd;
    padding: 2px 28px 2px 0;
    &:last-of-type{
      border: none !important;
    }
    &:hover {
      color: styles.$Bluebell;
    }
    .star{
      font-size: 0.85em !important;
      right: 2px !important;
    }
  }
}
.filter-column-toggle{
  color: styles.$Columbine;
  padding: 8px 0;
  cursor: pointer;
  font-size: 0.9em;
  border-top: 1px solid #ddd;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  -ms-grid-columns: 1fr 1fr;
  position: relative;
  .filter-toggle-option {
    text-align: center;
    padding: 2px 10px;
    font-size: 0.9em;
    &:hover{
      span {
        color: styles.$Bluebell;
        text-decoration: underline;
      }
    }
  }
  .filter-toggle-option:first-of-type {
    -ms-grid-column: 1;
    -ms-grid-column-span: 1;
  }
  .filter-toggle-option:nth-of-type(2) {
    -ms-grid-column: 2;
    -ms-grid-column-span: 1;
  }
  i{
    font-size: 0.8em;
    color: styles.$Poppy;
    margin-left: 6px;
    vertical-align: baseline;
    &.blue {
      color: styles.$Bluebell;
    }
    &:first-child {
      margin-right: 6px;
      margin-left: 0;
    }
  }
}
.star{
  position: absolute;
  color: styles.$Bluebell;
  font-size: 0.9em;
  top: 10px;
  right: 15px;
  display: flex;
  transition: all 0.18s;
  cursor: pointer;
  i{
    font-size: 0.9em;
    margin: auto;
  }
  .check{
    animation: pulse 200ms ease-in-out;
  }
  &.filter{
    top: 7px !important;
  }
}
