@use './styles';

$btn-default-color: styles.$Dahlia;
$btn-default-background: styles.$Dark_Lily;
$btn-default-background-hover: styles.$Dahlia;
$btn-default-background-hover-alt: styles.$Dark_Dahlia;
$btn-margin: styles.$padding;

.btn-base{
  @extend .source-sans;
  text-align: center;
  cursor: pointer;
  border: none;
  font-weight: 600;
  margin: $btn-margin;
  border-radius: 20px;
  min-width: 80px;
  font-size: 0.9em;
  padding: 4px 18px;
  display: inline-block;
  transition: background 0.13s ease-in-out, color 0.1s ease-in-out;
  & +.btn{
    margin-left: 0 !important;
  }
}
@mixin wws-btn($txt-color, $back-color, $back-color-hover){
  @extend .btn-base;
  background: $back-color;
  color: $txt-color;
  &:hover{
    background: $back-color-hover;
    color: white;
  }
}
.btn-grey{
  @include wws-btn($btn-default-color, $btn-default-background, $btn-default-background-hover);
}
.btn-dark{
  @include wws-btn(white, $btn-default-color, $btn-default-background-hover-alt);
  border: none;
}
.btn-special{
  @include wws-btn($btn-default-color, transparent, $btn-default-color);
  border: 2px solid $btn-default-color;
  padding: 2px 18px !important;
}
.wws-search-btn{
  position: absolute;
  width: 16px;
  height: 16px;
  right: 12px;
  cursor: pointer;
  top: 10px;
  background-color: transparent;
  background-image: url(styles.$svg_url + 'Search.svg');
  background-repeat: no-repeat;
  border: none;
}

// Copy html button styling
.copy-html, .edit-recent-updates{
  transition: all 0.18s;
}
