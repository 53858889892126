@use './styles';

/* Icons */
.fa-times-thin:before {
  content: '\00d7';
}
.continue{
  height: 5px;
  width: 15px;
  margin: auto 8px;
  display: inline-block;
  background-color: transparent;
  border: none;
  background-repeat: no-repeat;
  background-image: url(styles.$svg_url + 'readmore.svg');
}
.search-icon{
  background-color: transparent;
  background-image: url(styles.$svg_url + 'Search.svg');
  background-repeat: no-repeat;
  width: 12px;
  height: 12px;
}
.dropdown_arrow{
  background-image: url(styles.$svg_url + 'location_arrow.svg');
  width: 10px;
  top: 12px;
  right: 8px;
  position: absolute;
  height: 5px;
  background-repeat: no-repeat;
}
.dropdown_arrow_inline{
  background-image: url(styles.$svg_url + 'location_arrow.svg');
  width: 10px;
  height: 5px;
  background-repeat: no-repeat;
  display: inline-block;
  margin-left: 4px;
}
.expand-toggle-search {
  display: block;
  width: 100%;
  height: 0;
  transition: height 0.2s ease 0.1s;
  padding: 0 24px 0 36px;
  margin: 0;
  border: none;
  background: transparent;
  background-image: url(styles.$svg_url + 'Search.svg');
  background-repeat: no-repeat;
  background-position: 12px 50%;
  background-size: 10px 10px;
}
.radio{
  background-image: url(styles.$svg_url + 'radio_unchecked.svg');
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  cursor: pointer;
  margin: 6px 0px 0px 0px;
  float: right;
  &.checked{
    background-image: url(styles.$svg_url + 'radio_checked.svg') !important;
    animation: pulse 200ms ease-in-out;
  }
}
.pulse{
  animation: pulse 200ms ease-in-out;
}
.throb{
  animation: throb 200ms ease-in-out;
}
.fa-center {
  line-height: inherit !important;
  vertical-align: middle;
}
