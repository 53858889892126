@use './styles';

$padding: styles.$padding;

.card{
  width: 100%;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  background: white;
  margin-top: 16px;
  border: 1px solid #ddd;
  @media print{
    display: none;
  }
}
.news-item{
  padding: $padding*1.5;
  border: 1px solid #ddd;
  margin-bottom: $padding;
  background: white;
  position: relative;
}
.list-item{
  padding: $padding;
  border: 1px solid #ddd;
  margin-top: calc(#{$padding} / 2);
  background: white;
  position: relative;
}
.news{
  padding: $padding;
}
.date{
  @extend .source-sans;
  font-size: 0.9em;
  color: styles.$Light_Dahlia;
}
.tags{
  margin-left: 6px;
  font-size: 0.8em;
  display: inline-block;
  color: styles.$Dahlia;
  padding: 2px 6px;
  border-left: 1px solid styles.$Dark_Lily;
}
.news-footer{
  width: 100%;
  padding: 4px;
}
.news-continue{
  height: 100%;
  font-size: 0.9em;
  padding: 6px 0 2px;
  display: inline-block;
  text-decoration: underline;
  .continue-icon, .continue{
    vertical-align: middle;
    margin: auto 6px;
    color: styles.$Columbine;
    font-size: 0.5em;
  }
}
.spotlight{
  padding: 10px 18px;
  position: relative;
  border-top: 1px solid fade-out(#bbbbbb, 0.4);
  &:first-of-type {
    border: none !important;
  }
}
.news-type{
  border-radius: 100%;
  width: 35px;
  height: 35px;
  display: inline-block;
  margin: 2px 20px 2px 0;
  text-align: center;
  i{
    font-size: 0.9em;
    color: white;
    margin: auto;
    line-height: 2.6;
  }
  &.Text{
    background: styles.$Marigold !important;
  }
  &.Audio{
    background: styles.$Bluebell !important;
  }
  &.PDF{
    background: styles.$Poppy !important;
  }
  &.Video{
    background: styles.$Orchid !important;
  }
  &.PowerPoint{
    background: styles.$Poppy !important;
  }
  &.Word{
    background: styles.$Poppy !important;
  }
  &.Excel{
    background: styles.$Succulent !important;
  }
}
.resource-links{
  width: 100%;
  position: relative;
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
  background: white;
  margin-top: 16px;
  border: 1px solid #ddd;
  h5{
    margin: 12px 0 0 12px;
    cursor: pointer;
    i{
      margin-right: 16px;
      float: right;
      margin-top: 4px;
    }
  }
  ul{
    list-style: none;
    margin: 0 !important;
    padding: 0 24px;
    li{
      padding: 5px 4px;
      overflow: hidden;
      height: 34px;
      transition: all 0.18s ease-in-out;
    }
    &.list-closed li {
      height: 0;
      padding: 0;
    }
  }
  .big{
    padding-bottom: 12px;
  }
}
.resource-links-small{
  hr{
    margin-bottom: 15px !important;
  }
  ul{
    padding-left: 20px;
  }
  li{
    list-style: none;
    font-size: 1.3em;
  }
  @media print{
    display: none !important;
  }
}
.article-text{
  padding: 10px;
}
.recent-updates-filter{
  margin-left: 20px;
  vertical-align: top;
  color: styles.$Dahlia;
  select{
    border: none;
    border-bottom: 1px solid styles.$Dahlia;
  }
}
.result-item{
  padding: 8px;
  p{
    margin: 0 !important;
  }
}
.filter-button{
  display: inline-block;
  font-size: 0.9em;
  padding: 0 5px;
  cursor: pointer;
  margin: 0;
  position: relative;
  color: styles.$Columbine;
  i{
    font-size: 0.78em;
    margin-left: 2px;
    transition: all 0.18s;
  }
}
input:checked + .filter-button {
  i {
    transform: rotate(180deg);
  }
}
.modal-backdrop.show{
  opacity: 0.7 !important;
}

// Buttons that appear in h3 (as in Recent Updates)
.header-tool-container {
  right: 6px;
  top: 0;
  position: absolute;
  padding: 10px 6px;
  text-align: center;
  color: darken(styles.$Dark_Lily, 20%);
  cursor: pointer;
  &:hover {
    color: white;
  }
  .header-tool-icon {
    font-size: 1em;
    display: block;
  }
  .header-tool-text {
    margin-top: 2px;
    display: block;
    font-weight: 600;
    font-size: 0.5em;
  }
  &:nth-of-type(2) {
    right: 64px;
  }
}
